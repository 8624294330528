import axios from 'axios';
import { TableServiceClient, TableClient } from "@azure/data-tables";
import { InteractiveBrowserCredential } from "@azure/identity";

const apiUrlBase = 'https://dev.azure.com/cyaniccloud';
const apiVersion = '6.0';

const axiosInstance = axios.create({
  baseURL: apiUrlBase,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthHeader = (accessToken) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

const fetchProjects = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`_apis/projects?api-version=${apiVersion}`);
    return response.data.value;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

const fetchPipelines = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/tenants/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value;
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const fetchPipelinesWithFilter = async (accessToken) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/tenants/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.replace('\\', '').trim();
      return folder.length === 3;
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const fetchPipelinesWithFilterandProject = async (accessToken, projectName) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/${projectName}/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.replace('\\', '').trim();
      // Include pipelines where folder length is 3 or folder contains "Deploy Baseline Tenant Configuration" or "Capture Client Configuration Pipeline"
      return folder.length === 3 || folder.includes('Deploy Baseline Tenant Configuration');
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};


const fetchPipelinesbyName = async (accessToken, projectName) => {
  setAuthHeader(accessToken);
  try {
    const response = await axiosInstance.get(`/${projectName}/_apis/pipelines?api-version=7.1-preview.1`);
    return response.data.value.filter(pipeline => {
      const folder = pipeline.folder.charAt(0) === '\\' ? pipeline.folder.slice(1) : pipeline.folder;
      return folder.length === 3;
    });
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

const runPipeline = async (accessToken, projectId, pipelineId) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineId}/runs?api-version=7.1-preview.1`;

  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "variables": {}
  };

  try {
    await axiosInstance.post(apiUrl, body);
  } catch (error) {
    console.error('Error running pipeline:', error);
    throw error;
  }
};



const triggerBuild = async (accessToken, projectId, pipelineBuildId, appName) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineBuildId}/runs?api-version=7.1-preview.1`;
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {
      "AppList": `- ${appName}`
    },
    "variables": {}
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);
    return response.data;
  } catch (error) {
    console.error('Error triggering build:', error);
    throw error;
  }
};

const triggerAppDeployBuild = async (accessToken, projectId, pipelineBuildId, ClientCode, Domain, UserName, Password) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/${projectId}/_apis/pipelines/${pipelineBuildId}/runs?api-version=7.1-preview.1`;
  const body = {
    "stagesToSkip": [],
    "resources": {
      "repositories": {
        "self": {
          "refName": "refs/heads/main"
        }
      }
    },
    "templateParameters": {
      "ClientCode": ClientCode,
      "Domain": Domain
    },
    "variables": {
      "TenantAdminUserName": {
          "isSecret": false,
          "value": UserName
      },
      "TenantAdminUserPW": {
          "isSecret": true,
          "value": Password
      }
  }
  };

  try {
    const response = await axiosInstance.post(apiUrl, body);
    return response.data;
  } catch (error) {
    console.error('Error triggering build:', error);
    throw error;
  }
};

const fetchJsonFile = async (accessToken, repoId, path) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?path=${encodeURIComponent(path)}&recursionLevel=0&includeContentMetadata=true&versionDescriptor.version=main&versionDescriptor.versionOptions=0&versionDescriptor.versionType=0&includeContent=true&resolveLfs=true`;

  try {
    const response = await axiosInstance.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching JSON file from ${path}:`, error);
    throw error;
  }
};

const fetchClients = async (accessToken, repoId, path) => {
  setAuthHeader(accessToken);
  const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(path)}&recursionLevel=OneLevel&api-version=6.0`;

  try {
    const response = await axiosInstance.get(apiUrl);
    const directories = response.data.value.filter(item => item.isFolder);
    return directories.map(dir => dir.path.split('/').pop());
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

const compareJsonObjects = (referenceObject, differenceObject, basePath = '', excludedProperties = []) => {
  let comparisonResults = [];

  for (let property in referenceObject) {
    const fullPropertyPath = basePath ? `${basePath}.${property}` : property;

    if (excludedProperties.includes(fullPropertyPath)) {
      continue;
    }

    const refValue = referenceObject[property];
    const diffValue = differenceObject[property];

    if (diffValue === undefined) {
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: refValue,
        DiffValue: 'Missing',
        Equal: false
      });
      continue;
    }

    if (Array.isArray(refValue) && Array.isArray(diffValue)) {
      const arraysEqual = JSON.stringify(refValue) === JSON.stringify(diffValue);
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: JSON.stringify(refValue),
        DiffValue: JSON.stringify(diffValue),
        Equal: arraysEqual
      });
    } else if (typeof refValue === 'object' && refValue !== null && typeof diffValue === 'object' && diffValue !== null) {
      const subResults = compareJsonObjects(refValue, diffValue, fullPropertyPath, excludedProperties);
      comparisonResults = comparisonResults.concat(subResults);
    } else {
      const equal = refValue === diffValue;
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: refValue,
        DiffValue: diffValue,
        Equal: equal
      });
    }
  }

  for (let property in differenceObject) {
    const fullPropertyPath = basePath ? `${basePath}.${property}` : property;
    if (!Object.prototype.hasOwnProperty.call(referenceObject, property) && !excludedProperties.includes(fullPropertyPath)) {
      comparisonResults.push({
        Path: fullPropertyPath,
        RefValue: 'Missing',
        DiffValue: differenceObject[property],
        Equal: false
      });
    }
  }

  return comparisonResults;
};


const fetchAndCompareFiles = async (accessToken, repo, baselinePath, clientPath, excludedProperties) => {
  try {
    const baselineFile = await fetchJsonFile(accessToken, repo, baselinePath);
    const clientFile = await fetchJsonFile(accessToken, repo, clientPath);

    const baselineContent = JSON.parse(baselineFile.content);
    const clientContent = JSON.parse(clientFile.content);

    const comparisonResults = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
    console.log(comparisonResults);

    return comparisonResults;
  } catch (error) {
    console.error('Error comparing files:', error);
    throw error;
  }
};

const fetchAllJsonFiles = async (accessToken, repoId, path) => {
    const apiUrl = `https://dev.azure.com/cyaniccloud/1b238677-4a8d-4243-827e-88a0558c085a/_apis/git/repositories/${repoId}/items?scopePath=${encodeURIComponent(path)}&recursionLevel=Full&api-version=6.0`;
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
    };
  
    try {
      const response = await axios.get(apiUrl, { headers });
      const files = response.data.value.filter(item => !item.isFolder && item.path.endsWith('.json'));
      return files.map(file => file.path);
    } catch (error) {
      console.error(`Error fetching JSON files from ${path}:`, error);
      throw error;
    }
  };
  
  const fetchAndCompareAllFiles = async (accessToken, repoId, baselineDir, clientDir, excludedProperties) => {
    try {
      const baselineFiles = await fetchAllJsonFiles(accessToken, repoId, baselineDir);
      const clientFiles = await fetchAllJsonFiles(accessToken, repoId, clientDir);
  
      let comparisonResults = [];
  
      for (let baselineFile of baselineFiles) {
        const relativePath = baselineFile.replace(baselineDir, '');
        const clientFilePath = clientFiles.find(clientFile => clientFile.endsWith(relativePath));
  
        if (clientFilePath) {
          const baselineFileContent = await fetchJsonFile(accessToken, repoId, baselineFile);
          const clientFileContent = await fetchJsonFile(accessToken, repoId, clientFilePath);
  
          const baselineContent = JSON.parse(baselineFileContent.content);
          const clientContent = JSON.parse(clientFileContent.content);
  
          const results = compareJsonObjects(baselineContent, clientContent, '', excludedProperties);
          comparisonResults = comparisonResults.concat(results);
        } else {
          comparisonResults.push({
            Path: relativePath,
            RefValue: JSON.parse((await fetchJsonFile(accessToken, repoId, baselineFile)).content),
            DiffValue: 'Missing',
            Equal: false
          });
        }
      }
  
      return comparisonResults;
    } catch (error) {
      console.error('Error comparing files:', error);
      throw error;
    }
  };

// Define your Azure Storage account name and key here
const accountName = 'cccadostrgwe';

// Function to create a TableServiceClient
async function createTableServiceClient() {
  const credential = new InteractiveBrowserCredential({
    clientId: '1da955b1-4ce4-481a-a857-0423ac697927', // replace with your client ID
    tenantId: 'bcc93690-805b-4911-b8c1-6e447795ef8b', // replace with your tenant ID
  });
  return new TableServiceClient(
    `https://${accountName}.table.core.windows.net`,
    credential
  );
}

const listAzureTables = async () => {
  const tableServiceClient = await createTableServiceClient();
  const tables = tableServiceClient.listTables();
  const result = [];
  for await (const table of tables) {
    result.push(table);
  }
  return result;
};

// Function to get table contents
const getTableContents = async (tableName) => {
  const credential = new InteractiveBrowserCredential({
    clientId: '1da955b1-4ce4-481a-a857-0423ac697927', // replace with your client ID
    tenantId: 'bcc93690-805b-4911-b8c1-6e447795ef8b', // replace with your tenant ID
  });
  const tableClient = new TableClient(
    `https://${accountName}.table.core.windows.net`,
    tableName,
    credential
  );
  const entitiesIter = tableClient.listEntities();
  const entities = [];
  for await (const entity of entitiesIter) {
    entities.push(entity);
  }
  return entities;
};

// Function to create a client table

const createClientTable = async (tableName) => {
  const tableServiceClient = await createTableServiceClient();
  let status = 201;
  let message = `Table '${tableName}' created successfully.`;

  try {
    await tableServiceClient.createTable(tableName, {
      onResponse: (response) => {
        if (response.status === 409) {
          status = 409;
          message = `Table '${tableName}' already exists.`;
        }
      }
    });
  } catch (error) {
    status = error.statusCode || 500;
    message = error.message || 'An unexpected error occurred';
  }

  return { status, message };
};
// Function to update a client table
const updateClientTable = async (tableName, entity) => {
  const tableServiceClient = await createTableServiceClient();
  try {
    
    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      tableServiceClient
    );

    await tableClient.createEntity(entity);
    return { status: 200, message: `Table '${tableName}' updated successfully.` };
  } catch (error) {
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

const updateEntity = async (tableName, entity) => {
  const tableServiceClient = await createTableServiceClient();
  try {
    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      tableServiceClient
    );

    await tableClient.updateEntity(entity, "Replace");
    return { status: 200, message: `Entity in table '${tableName}' updated successfully.` };
  } catch (error) {
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

const deleteClientTable = async (tableName, partitionKey, rowKey) => {
  const credential = await createTableServiceClient();
  try {
    const tableClient = new TableClient(
      `https://${accountName}.table.core.windows.net`,
      tableName,
      credential
    );

    await tableClient.deleteEntity(partitionKey, rowKey);
    return { status: 200, message: `Entity deleted successfully.` };
  } catch (error) {
    console.error('Error deleting entity:', error);
    return { status: error.statusCode || 500, message: error.message || 'An unexpected error occurred' };
  }
};

export {
  fetchProjects,
  fetchPipelines,
  runPipeline,
  fetchPipelinesbyName,
  triggerBuild,
  fetchJsonFile,
  fetchClients,
  compareJsonObjects,
  fetchAndCompareFiles,
  fetchAndCompareAllFiles,
  fetchAllJsonFiles,
  listAzureTables,
  getTableContents,
  createClientTable,
  updateClientTable,
  updateEntity,
  deleteClientTable,
  triggerAppDeployBuild,
  fetchPipelinesWithFilter,
  fetchPipelinesWithFilterandProject
};
