import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/components/UserLogin.vue';
import AzureADOProjects from '../views/components/AzureADOProjects.vue';
import PipelinesList from '../views/components/PipelinesList.vue';
import AssociatedPipelines from '../views/components/AssociatedPipelines.vue';
import FetchJsonFiles from '../views/components/FetchJsonFiles.vue';
import StorageTableViewer from '../views/components/StorageTableViewer.vue';
import CreateAzureStorageTable from '../views/components/CreateAzureStorageTable.vue';
import UpdateExistingTable from '../views/components/UpdateExistingTable.vue';
import OnBoardAppDeployClient from '../views/components/OnBoardAppDeployClient.vue';
import TenantBaseline from '../views/components/TenantBaseline.vue';
import { msalInstance } from '../auth';

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/azure-ado-projects',
    name: 'AzureADOProjects',
    component: AzureADOProjects,
    meta: { requiresAuth: true },
  },
  {
    path: '/pipelines-list',
    name: 'PipelinesList',
    component: PipelinesList,
    meta: { requiresAuth: true },
  },
  {
    path: '/associated-pipelines/:projectName/:projectId',
    name: 'AssociatedPipelines',
    component: AssociatedPipelines,
    meta: { requiresAuth: true },
  },
  {
    path: '/fetch-json-files',
    name: 'FetchJsonFiles',
    component: FetchJsonFiles,
    meta: { requiresAuth: true },
  },
  {
    path: '/storage-tables-list',
    name: 'StorageTableViewer',
    component: StorageTableViewer,
    meta: { requiresAuth: true },
  },
  {
    path: '/Create-Azure-Storage-Table',
    name: 'CreateAzureStorageTable',
    component: CreateAzureStorageTable,
    meta: { requiresAuth: true },
  },
  {
    path: '/update-existing-table',
    name: 'update-existing-table',
    component: UpdateExistingTable,
    meta: { requiresAuth: true },
  },
  {
    path: '/AppDeploy-Onboarding',
    name: 'AppDeploy-Onboarding',
    component: OnBoardAppDeployClient,
    meta: { requiresAuth: true },
  }
  ,
  {
    path: '/tenant-baseline',
    name: 'TenantBaseline',
    component: TenantBaseline,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = msalInstance.getAllAccounts().length > 0;

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
