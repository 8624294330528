<template>
  <nav>
    <div class="nav-left">
      <router-link to="/azure-ado-projects">Projects</router-link>
      <router-link to="/pipelines-list">Pipelines</router-link>
      <router-link to="/fetch-json-files">Aegis</router-link>
      <router-link to="/storage-tables-list">App Deploy</router-link>
      <router-link to="/tenant-baseline">Tenant Baseline</router-link>
    </div>
    <button v-if="userIsLoggedIn" @click="logout" class="logout-button">Logout</button>
  </nav>
</template>

<script>
import { logout } from "@/auth.js";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "NavBar",
  setup() {
    const store = useStore();
    const userIsLoggedIn = computed(() => store.state.isLoggedIn);

    return {
      userIsLoggedIn,
      logout,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

nav {
  display: flex;
  justify-content: space-between;
  background-color: #0e223b;
  padding: 1rem;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.nav-left {
  display: flex;
  align-items: center;
}

nav a {
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  transition: color 0.2s;
}

nav a:hover {
  color: #38b35f;
}

.logout-button {
  background-color: #38b35f;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.logout-button:hover {
  background-color: #ffffff;
  color: #0e223b;
}
</style>
