<template>
  <div class="AppDeploy-onboarding-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="main-content">
      <h1>Onboard App Deploy Client</h1>
      <form @submit.prevent="handleSubmit" class="form">
        <div class="form-group">
          <label for="client-code">Client Code:</label>
          <input 
            type="text" 
            id="client-code" 
            v-model="clientCode" 
            @input="validateClientCode"
            :class="{ 'is-invalid': invalidFields.includes('clientCode') || clientCodeError }"
            required 
          />
          <span v-if="clientCodeError" class="error-message">{{ clientCodeError }}</span>
          <span v-if="clientCodeSuccess" class="success-message">{{ clientCodeSuccess }}</span>
        </div>
        <div class="form-group">
          <label for="domain">Domain:</label>
          <input 
            type="text" 
            id="domain" 
            v-model="domain" 
            :class="{ 'is-invalid': invalidFields.includes('domain') }"
            required 
          />
        </div>
        <div class="form-group">
          <label for="username">Username:</label>
          <input 
            type="text" 
            id="username" 
            v-model="username" 
            :class="{ 'is-invalid': invalidFields.includes('username') }"
            required 
          />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            v-model="password" 
            :class="{ 'is-invalid': invalidFields.includes('password') }"
            required 
          />
        </div>
        <button type="submit" class="primary-button" :disabled="clientCodeError || !clientCodeValidated">Submit</button>
      </form>
      <div v-if="formError" class="error-message">
        Please fill in all the required fields.
      </div>
      <div v-if="message.text" :class="['message', message.type]">
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script>
// Import the necessary components and API functions
import NavBar from '@/views/components/NavBar.vue';
import { mapState } from 'vuex';
import { msalInstance } from '@/auth.js';
import { triggerAppDeployBuild, listAzureTables, fetchPipelinesbyName } from '@/azure-devops-api.js'; // Import fetchPipelinesbyName

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      clientCode: '',
      domain: '',
      username: '',
      password: '',
      formError: false,
      invalidFields: [],
      message: {
        text: '',
        type: '', // 'success' or 'error'
      },
      clientCodeError: '',
      clientCodeSuccess: '',
      clientCodeValidated: false,
      pipelineExists: false, // New state to track if a pipeline exists
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
  },
  methods: {
    validateForm() {
      this.invalidFields = [];
      if (!this.clientCode) this.invalidFields.push('clientCode');
      if (!this.domain) this.invalidFields.push('domain');
      if (!this.username) this.invalidFields.push('username');
      if (!this.password) this.invalidFields.push('password');
      return this.invalidFields.length === 0;
    },

    // Validate client code format and ensure it exists in Azure Storage tables
    async validateClientCode() {
      const clientCodePattern = /^[A-Z]{3}$/;
      if (!clientCodePattern.test(this.clientCode)) {
        this.clientCodeError = 'Client code must be exactly 3 uppercase letters.';
        this.clientCodeSuccess = '';
        this.clientCodeValidated = false;
        this.pipelineExists = false;
      } else {
        this.clientCodeError = '';
        await this.checkClientCodeInTables();
        if (!this.clientCodeError) {
          await this.checkForExistingPipeline(); // Check if the pipeline exists
        }
      }
    },

    // Check if client code exists in Azure Storage tables
    async checkClientCodeInTables() {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['https://storage.azure.com/.default'],
          };
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          const accessToken = response.accessToken;

          const tables = await listAzureTables(accessToken);
          const tableExists = tables.some(table => table.name.startsWith(this.clientCode));

          if (tableExists) {
            this.clientCodeSuccess = `Table found for client code ${this.clientCode}.`;
            this.clientCodeError = '';
            this.clientCodeValidated = true;
          } else {
            this.clientCodeError = `No tables found starting with ${this.clientCode}. Please create Client App Table!`;
            this.clientCodeSuccess = '';
            this.clientCodeValidated = false;
          }
        } else {
          throw new Error('No accounts available for authentication.');
        }
      } catch (error) {
        console.error('Error checking client code in tables:', error);
        this.clientCodeError = 'Error checking client code in tables.';
        this.clientCodeSuccess = '';
        this.clientCodeValidated = false;
      }
    },

    // Check if an Azure DevOps pipeline already exists for the client
    async checkForExistingPipeline() {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
          };
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          const accessToken = response.accessToken;

          const pipelines = await fetchPipelinesbyName(accessToken, 'Tenants'); // Replace with your actual project name
          const pipelineExists = pipelines.some(pipeline => pipeline.folder.includes(this.clientCode));

          if (pipelineExists) {
            this.clientCodeError = `${this.clientCode} has already been onboarded.`;
            this.clientCodeValidated = false;
            this.pipelineExists = true;
          } else {
            this.pipelineExists = false;
          }
        } else {
          throw new Error('No accounts available for authentication.');
        }
      } catch (error) {
        console.error('Error checking pipelines:', error);
        this.clientCodeError = 'Error checking pipelines.';
        this.clientCodeValidated = false;
        this.pipelineExists = true;
      }
    },

    // Handle form submission
    async handleSubmit() {
      if (!this.validateForm()) {
        this.formError = true;
        return;
      }

      if (this.pipelineExists) {
        this.message.text = 'Client is already onboarded. Please check the existing pipeline.';
        this.message.type = 'error';
        return;
      }

      // Proceed with submission if no pipeline exists
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
          };
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          const accessToken = response.accessToken;

          const formData = {
            clientCode: this.clientCode,
            domain: this.domain,
            username: this.username,
            password: this.password,
          };

          console.log('Form data being submitted:', formData); // Use formData here


          const projectId = '65a332c6-445e-4c72-a097-db3513d145d5'; // Replace with actual project ID
          const pipelineBuildId = '55'; // Replace with actual pipeline build ID

          const buildResponse = await triggerAppDeployBuild(
            accessToken,
            projectId,
            pipelineBuildId,
            this.clientCode,
            this.domain,
            this.username,
            this.password
          );

          if (buildResponse && buildResponse.state === 'inProgress') {
            this.message.text = 'Pipeline triggered successfully!';
            this.message.type = 'success';

            // Clear form fields
            this.clientCode = '';
            this.domain = '';
            this.username = '';
            this.password = '';
            this.clientCodeValidated = false;
            this.formError = false;
            this.invalidFields = [];
          } else {
            this.message.text = 'Failed to trigger the pipeline.';
            this.message.type = 'error';
          }
        } else {
          throw new Error('No accounts available for authentication.');
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        this.message.text = 'Error triggering build: ' + error.message;
        this.message.type = 'error';
      }
    },
  },
  async created() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      const accessTokenRequest = {
        account: accounts[0],
        scopes: ['https://storage.azure.com/.default'],
      };
      try {
        const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
        const accessToken = response.accessToken;
        this.tables = await listAzureTables(accessToken);
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    }
  },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  background-color: #e8f0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0E223B;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0E223B;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38B35F;
}

.main-content {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

form {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #1c7ed6;
  outline: none;
}

.primary-button {
  font-family: 'Roboto', sans-serif;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #38B35F;
  color: white;
  transition: background-color 0.3s ease;
  align-self: center;
  width: 50%;
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.is-invalid {
  border-color: #dc3545 !important;
  background-color: #fff !important;
}

.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

/* Adjust error and success message styles */
.error-message, .success-message {
  display: block; /* Ensure each message takes its own block */
  width: 100%; /* Make the message span the full width */
  clear: both; /* Ensure no floating or inline elements interfere */
  padding: 10px;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem; /* Add margin between error and success messages */
  text-align: center;
  position: relative;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.success-message {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.message {
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  margin-bottom: 1rem;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  margin-bottom: 1rem;
}
</style>


