<template>
  <div class="dashboard-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="main-content">
      <header class="content-header">
        <h1>Options - Aegis</h1>
        <div class="dropdown">
          <label for="client-select">Select Client to begin Comparison Checks:</label>
          <select id="client-select" v-model="selectedClient">
            <option v-for="client in clients" :key="client" :value="client">{{ client }}</option>
          </select>
          <button @click="fetchClientConfig">Fetch Configuration</button>
          <button @click="compareFiles">Compare Files</button>
        </div>
      </header>
      <div class="content">
        <div v-if="loading" class="loading">Loading...</div>
        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
        <div v-if="showConfigurations && !comparisonResults.length" class="config-container">
          <div class="config">
            <h2>Baseline Configuration</h2>
            <pre>{{ formattedBaselineConfig }}</pre>
          </div>
          <div class="config">
            <h2>Client Configuration</h2>
            <pre>{{ formattedClientConfig }}</pre>
          </div>
        </div>
        <div v-if="comparisonResults.length" class="comparison-results">
          <h2>Comparison Results</h2>
          <table>
            <thead>
              <tr>
                <th>Setting</th>
                <th>Desired State</th>
                <th>Client Tenant</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="result in comparisonResults" :key="result.Path" :class="{ 'pass': result.Equal, 'fail': !result.Equal }">
                <td>{{ result.Path }}</td>
                <td>{{ result.RefValue }}</td>
                <td>{{ result.DiffValue }}</td>
                <td :class="{ 'status-pass': result.Equal, 'status-fail': !result.Equal }">
                  {{ result.Equal ? 'Pass' : 'Fail' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/views/components/NavBar.vue';
import { msalInstance } from '@/auth.js';
import { fetchClients, fetchAndCompareAllFiles, fetchAllJsonFiles, fetchJsonFile } from '@/azure-devops-api.js';
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      selectedClient: '',
      clients: [],
      baselineConfig: {},
      clientConfig: {},
      comparisonResults: [],
      showConfigurations: false,
      loading: false,
      errorMessage: ''
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    formattedBaselineConfig() {
      return JSON.stringify(this.baselineConfig, null, 2);
    },
    formattedClientConfig() {
      return JSON.stringify(this.clientConfig, null, 2);
    }
  },
  async created() {
    this.loading = true;
    try {
      const accessToken = await this.acquireToken();
      this.clients = await fetchClients(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', '/Clients');
    } catch (error) {
      this.errorMessage = 'Error fetching clients: ' + error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async acquireToken() {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
        };
        const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
        return response.accessToken;
      } else {
        throw new Error('No accounts available for authentication.');
      }
    },
    async fetchClientConfig() {
  this.showConfigurations = false;
  this.comparisonResults = [];
  this.loading = true;
  this.errorMessage = '';

  try {
    const accessToken = await this.acquireToken();
    const baselineDir = '/M365Config/Resources';
    const clientDir = `/Clients/${this.selectedClient}/M365Config/Resources`;

    // Fetch baseline and client files in parallel
    const [baselineFiles, clientFiles] = await Promise.all([
      fetchAllJsonFiles(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', baselineDir),
      fetchAllJsonFiles(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', clientDir)
    ]);

    this.baselineConfig = {};
    this.clientConfig = {};

    // Fetch file contents in parallel
    const baselineFilePromises = baselineFiles.map(file => 
      fetchJsonFile(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', file).then(response => ({
        relativePath: file.replace(baselineDir, ''),
        content: JSON.parse(response.content)
      }))
    );

    const clientFilePromises = clientFiles.map(file => 
      fetchJsonFile(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', file).then(response => ({
        relativePath: file.replace(clientDir, ''),
        content: JSON.parse(response.content)
      }))
    );

    const baselineFileContents = await Promise.all(baselineFilePromises);
    const clientFileContents = await Promise.all(clientFilePromises);

    baselineFileContents.forEach(file => {
      this.baselineConfig[file.relativePath] = file.content;
    });

    clientFileContents.forEach(file => {
      this.clientConfig[file.relativePath] = file.content;
    });

    this.showConfigurations = true;
  } catch (error) {
    this.errorMessage = 'Error fetching client configuration: ' + error.message;
  } finally {
    this.loading = false;
  }
},

    async compareFiles() {
      if (!this.showConfigurations) {
        await this.fetchClientConfig();
      }

      this.loading = true;
      this.errorMessage = '';

      try {
        const accessToken = await this.acquireToken();
        const baselineDir = '/M365Config/Resources';
        const clientDir = `/Clients/${this.selectedClient}/M365Config/Resources`;

        this.comparisonResults = await fetchAndCompareAllFiles(accessToken, '62b297c6-760c-4250-a926-52b8850e6d5f', baselineDir, clientDir, [
          'DefaultMailboxRegion', 'AllowedMailboxRegions', 'RejectAnonymousDirectSend', 'RPSEnabled', 'appStoreUrl', 'DefaultAuthenticationPolicy', 'ReleaseTrack', 'Identity', 'Name', 'InPlaceHolds', 'deviceNameTemplate', 'Size', 'largeIcon.value', 'deployedAppCount', 'ImmutableId', 'Priority', 'CreatedBy', 'LastModifiedBy', 'Guid', 'OrganizationId', 'OrganizationalUnitRoot', 'ObjectCategory', 'DistinguishedName', 'UnifiedAuditLogFirstOptInDate', '@odata.type', 'id', 'securityIdentifier', 'authenticationStrength@odata.context', 'grantControls.authenticationStrength@odata.context', 'advancedThreatProtectionOnboardingBlob', 'description', 'version', 'createdDateTime', 'lastModifiedDateTime', 'settings@odata.context', 'informationUrl', 'ExchangeObjectId', 'GroupLifeCyclePolicy', 'GroupsCreationWhitelistedId', 'AdminDisplayVersion', 'RBACConfigurationVersion', 'LegacyExchangeDN', 'SharePointUrl', 'MicrosoftExchangeRecipientPrimarySmtpAddress', 'MicrosoftExchangeRecipientEmailAddresses'
        ]);

        this.showConfigurations = true;
      } catch (error) {
        this.errorMessage = 'Error comparing files: ' + error.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  background-color: #e8f0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0E223B;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0E223B;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38B35F;
}

.main-content {
  width: 100%;
  height: calc(100vh - 80px); /* Adjusted height */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.content-header {
  background-color: #F1F2F3;
  color: #2A2C53;
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;
  font-family: 'Roboto', sans-serif;
}

.dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

label {
  font-size: 1.1rem;
  color: #2A2C53;
  margin-right: 10px;
}

select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

select:focus {
  border-color: #1e90ff;
  box-shadow: 0 0 8px rgba(30, 144, 255, 0.5);
  outline: none;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #38B35F;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

button:hover {
  background-color: #2A2C53;
  transform: translateY(-2px);
}

button:active {
  background-color: #2A2C53;
  transform: translateY(0);
}

button:not(:last-child) {
  margin-right: 10px;
}

.content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.config-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}

.config {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Allow horizontal scrolling if content overflows */
}

.comparison-results {
  width: 100%;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  color: #333;
  max-height: 400px;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
  table-layout: fixed;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th {
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
  padding: 15px 12px;
  text-align: left;
}

td {
  padding: 15px 12px;
  text-align: left;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

th:nth-of-type(1),
td:nth-of-type(1) {
  width: 25%;
}

th:nth-of-type(2),
td:nth-of-type(2) {
  width: 25%;
}

th:nth-of-type(3),
td:nth-of-type(3) {
  width: 25%;
}

th:nth-of-type(4),
td:nth-of-type(4) {
  width: 10%;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e9e9e9;
}

td.status-pass {
  background-color: Green;
  color: white;
  font-weight: bold;
  text-align: center;
}

td.status-fail {
  background-color: Red;
  color: white;
  font-weight: bold;
  text-align: center;
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.error {
  color: red;
  font-size: 1.2rem;
  text-align: center;
}
</style>
