<template>
  <div class="storage-tables-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="storage-tables-list">
      <h1>App Deploy Table Viewer</h1>
      <p class="description">
        Select a table to view existing App Deployment Lists or Create and Update App Lists.
      </p>
      <div class="button-container">
        <button v-if="!selectedTable" @click="fetchTables" class="primary-button">Refresh Tables</button>
        <button v-if="selectedTable" @click="showTableList" class="secondary-button">Back to Table List</button>
        <router-link v-if="!selectedTable" to="/create-azure-storage-table" class="primary-button">Create New Client Table</router-link>
        <router-link v-if="!selectedTable" to="/update-existing-table" class="primary-button">Update Existing Table</router-link>
        <router-link v-if="!selectedTable" to="/AppDeploy-Onboarding" class="primary-button">Onboard App Deploy Client</router-link>
      </div>

      <ul v-if="!selectedTable" class="table-list">
        <li v-for="table in tables" :key="table.name" @click="fetchTableContents(table.name)" class="table-item">
          {{ table.name }}
        </li>
      </ul>

      <div v-if="selectedTable && tableContents.length" class="table-wrapper">
        <h2>{{ selectedTable }} Applications</h2>

        <!-- Message display section -->
        <div v-if="message.text" :class="['message', message.type]">
          {{ message.text }}
        </div>

        <div class="table-scroll-container">
          <div class="table-scrollbar" ref="topScroll">
            <div class="table-scroll-content"></div>
          </div>
          <div class="table-container" ref="tableContainer">
            <table>
              <thead>
                <tr>
                  <th v-for="header in tableHeaders" :key="header" @click="sortTable(header)">
                    {{ header }} <span v-if="sortColumn === header">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in sortedTableContents" :key="row.PartitionKey + '-' + row.rowKey">
                  <td v-for="header in tableHeaders" :key="header" class="table-cell">{{ row[header] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-scrollbar" ref="bottomScroll">
            <div class="table-scroll-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/views/components/NavBar.vue';
import { msalInstance } from '@/auth.js';
import { listAzureTables, getTableContents } from '@/azure-devops-api.js';
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      tables: [],
      selectedTable: null,
      tableContents: [],
      tableHeaders: [],
      sortColumn: '',
      sortOrder: 'asc',
      message: {
        text: '',
        type: '', // 'success' or 'error'
      },
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    sortedTableContents() {
      if (!this.sortColumn) return this.tableContents;
      return this.tableContents.slice().sort((a, b) => {
        const aValue = a[this.sortColumn];
        const bValue = b[this.sortColumn];
        if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!msalInstance.getAllAccounts().length) {
      next('/login');
    } else {
      next();
    }
  },
  async created() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      const accessTokenRequest = {
        account: accounts[0],
        scopes: ['https://storage.azure.com/.default'],
      };
      try {
        await msalInstance.acquireTokenSilent(accessTokenRequest);
        this.tables = await listAzureTables();
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    }
  },
  methods: {
    async fetchTables() {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (!accounts || !accounts.length) {
          throw new Error("User is not logged in");
        }

        const accessTokenRequest = {
          account: accounts[0],
          scopes: ['https://storage.azure.com/.default'],
        };
        await msalInstance.acquireTokenSilent(accessTokenRequest);
        this.tables = await listAzureTables();
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    },
    async fetchTableContents(tableName) {
      try {
        this.selectedTable = tableName;
        const contents = await getTableContents(tableName);
        console.log(contents); // Debugging log
        this.tableContents = contents;
        const headers = Object.keys(contents[0]);
        this.tableHeaders = ['AppName', 'Version', ...headers.filter(h => h !== 'AppName' && h !== 'Version')];
        this.$nextTick(() => {
          this.updateScrollContentWidth();
          this.syncScroll();
        });
      } catch (error) {
        console.error("Error fetching table contents:", error);
      }
    },
    showTableList() {
      this.selectedTable = null;
      this.tableContents = [];
      this.tableHeaders = [];
    },
    updateScrollContentWidth() {
      const tableContainer = this.$refs.tableContainer;
      const scrollContentTop = this.$refs.topScroll.querySelector('.table-scroll-content');
      const scrollContentBottom = this.$refs.bottomScroll.querySelector('.table-scroll-content');
      scrollContentTop.style.width = `${tableContainer.scrollWidth}px`;
      scrollContentBottom.style.width = `${tableContainer.scrollWidth}px`;
    },
    syncScroll() {
      const topScroll = this.$refs.topScroll;
      const bottomScroll = this.$refs.bottomScroll;
      const tableContainer = this.$refs.tableContainer;

      const sync = (scrollElement) => {
        return () => {
          topScroll.scrollLeft = scrollElement.scrollLeft;
          bottomScroll.scrollLeft = scrollElement.scrollLeft;
          tableContainer.scrollLeft = scrollElement.scrollLeft;
        };
      };

      topScroll.addEventListener('scroll', sync(topScroll));
      bottomScroll.addEventListener('scroll', sync(bottomScroll));
      tableContainer.addEventListener('scroll', sync(tableContainer));
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0E223B;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0E223B;
  padding: 10px 0;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38B35F;
}

.storage-tables-list {
  max-width: 1650px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.button-container {
  margin-bottom: 20px;
}

.primary-button, .secondary-button {
  font-family: 'Roboto', sans-serif;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button {
  background-color: #38B35F;
  color: white;
  text-decoration: none; /* Remove underline */
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.secondary-button {
  background-color: #555;
  color: white;
}

.secondary-button:hover {
  background-color: #444;
}

.button-link {
  display: inline-block; /* Ensure it's block-level */
  text-align: center;
}

ul.table-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li.table-item {
  background-color: #0E223B;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1rem;
  padding: 15px 20px;
  border-radius: 4px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

li.table-item:hover {
  background-color: #1c7ed6;
}

.actions {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.actions input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 300px;
}

.table-wrapper {
  width: 100%;
  overflow: hidden;
}

.table-scroll-container {
  position: relative;
  width: 100%;
}

.table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
}

.table-scroll-content {
  height: 1px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  min-width: 600px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

th {
  background-color: #f4f4f4;
  cursor: pointer;
}

th span {
  font-size: 0.8rem;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.table-cell {
  max-width: 150px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}
</style>
