<template>
  <div class="landing-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar">
        <div class="container">
          <nav class="nav">
            <ul class="nav-items">
              <li><a href="#products">Products</a></li>
              <li><a href="#customers">Customers</a></li>
              <li><a href="#about-us">About Us</a></li>
              <li><a href="#support">Support</a></li>
            </ul>
            <ul class="nav-login">
              <li><a href="#login" @click="login" class="cta-button login-button">Login</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
    <main class="main">
      <section class="hero">
        <div class="container">
          <h1>Welcome to Cyanic Cloud</h1>
          <p>The best solution for your needs</p>
          <a href="#features" class="cta-button">Learn More</a>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container">
          <h2>Features</h2>
          <p>Explore the amazing features of Cyanic Cloud</p>
          <div class="feature-cards">
            <div class="feature-card">
              <h3>Security & Compliance</h3>
              <p>Deploy entire environments from existing configurations. Copy and move individual configurations between tenants. Push changes to tenants in bulk to save time and eliminate mistakes</p>
              <div class="image-container">
                <img src="@/assets/security_compliance.png" alt="Security & Compliance Image" />
              </div>
            </div>
            <div class="feature-card">
              <h3>Enterprise Application Packaging</h3>
              <p>Easily create and manage Intune applications. Update and deploy new versions via automation. Deploy application packages to tenants in bulk</p>
              <div class="image-container">
                <img src="@/assets/packagemanagement.png" alt="Enterprise Application Packaging Image" />
              </div>
            </div>
            <div class="feature-card">
              <h3>Monitoring & Reporting</h3>
              <p>Programmatically document and track changes to configurations</p>
              <div class="image-container">
                <img src="@/assets/Monitoring_Reporting.png" alt="Monitoring & Reporting Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="pricing">
        <div class="container">
          <h2>Pricing</h2>
          <p>Choose the plan that suits you best</p>
          <div class="pricing-cards">
            <div class="pricing-card">
              <h3>Basic Plan</h3>
              <p></p>
              <a href="#signup" class="cta-button">Contact Sales</a>
            </div>
            <div class="pricing-card">
              <h3>Pro Plan</h3>
              <p></p>
              <a href="#signup" class="cta-button">Contact Sales</a>
            </div>
            <div class="pricing-card">
              <h3>Enterprise Plan</h3>
              <p></p>
              <a href="#signup" class="cta-button">Contact Sales</a>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" class="contact">
        <div class="container">
          <h2>Contact Us</h2>
          <p>We would love to hear from you</p>
          <form class="contact-form">
            <input type="text" placeholder="Name" class="form-input">
            <input type="email" placeholder="Email" class="form-input">
            <textarea placeholder="Message" class="form-input"></textarea>
            <button type="submit" class="cta-button">Send Message</button>
          </form>
        </div>
      </section>
    </main>
    <footer class="footer">
      <div class="container">
        <p>&copy; 2024 Options IT. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { msalInstance } from "@/auth.js";
import store from "@/store";

export default {
  name: "LandingPage",
  methods: {
    async login() {
      try {
        const currentAccount = msalInstance.getActiveAccount();
        await msalInstance.handleRedirectPromise();
        if (!currentAccount) {
          await msalInstance.loginPopup({
            scopes: [
              "openid",
              "profile",
              "email",
              "offline_access",
              "User.Read",
              "499b84ac-1321-427f-aa17-267ca6975798/user_impersonation",
            ],
          });
        }
        this.$router.push("/azure-ado-projects");
        store.commit('setLoggedIn', true);
      } catch (error) {
        console.error("Login error:", error);
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.header {
  background: #0E223B;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0E223B;
  padding: 10px 0;
}

.header .nav {
  display: flex;
  justify-content: space-between;
}

.nav-items, .nav-login {
  display: flex;
  align-items: center;
}

.nav-items {
  gap: 20px;
}

.nav-login {
  margin-left: auto;
}

.nav-items li, .nav-login li {
  list-style: none;
}

.nav-items a, .nav-login a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-items a:hover, .nav-login a:hover {
  color: #38B35F;
}

.main {
  padding: 20px 0;
}

.hero {
  background: linear-gradient(90deg, #0D173F 0%, #2A2C53 100%);
  color: #fff;
  text-align: center;
  padding: 50px 20px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero .cta-button {
  background: #38B35F;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.hero .cta-button:hover {
  background: #2A2C53;
}

.features, .pricing, .contact {
  padding: 60px 0;
  text-align: center;
}

.features h2, .pricing h2, .contact h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.features p, .pricing p, .contact p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.feature-cards, .pricing-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card, .pricing-card {
  background: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-card h3, .pricing-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.image-container {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cta-button {
  background: #38B35F;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease;
  text-decoration: none;
}

.cta-button:hover {
  background: #2A2C53;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

.footer {
  background: #0E223B;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

/* Media Queries for Responsive Design */
@media (max-width: 767px) {
  .container {
    width: 90%;
    padding: 10px;
  }

  .header .nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-items, .nav-login {
    flex-direction: column;
    gap: 10px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .feature-cards, .pricing-cards {
    flex-direction: column;
  }

  .feature-card, .pricing-card {
    width: 100%;
    margin: 10px 0;
  }

  .feature-card h3, .pricing-card h3 {
    font-size: 1.2rem;
  }

  .image-container {
    height: 150px;
  }

  .features h2, .pricing h2, .contact h2 {
    font-size: 2rem;
  }

  .features p, .pricing p, .contact p {
    font-size: 1rem;
  }

  .contact-form {
    width: 100%;
  }
}
</style>
