<template>
  <div class="update-table-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="update-table-container">
      <h1>Update Existing App Deploy Table</h1>
      <p class="description">
        Select a table to update and choose the type of update you want to perform. You can add existing applications, create a new one, delete existing applications, add a new property, delete a property, or update an existing application.
      </p>
      <div class="actions">
        <select v-model="selectedTable" @change="onTableChange" class="select-element">
          <option value="" disabled>Select Table to Update</option>
          <option v-for="table in tables" :key="table.name" :value="table.name">
            {{ table.name }}
          </option>
        </select>
        <select v-if="selectedTable" v-model="updateType" @change="onUpdateTypeChange" class="select-element">
          <option value="" disabled>Select Update Type</option>
          <option value="existing">Add - Existing App</option>
          <option value="new">Add - New App</option>
          <option value="newProperty">Add - New Property</option>
          <option value="delete">Delete - Existing App</option>
          <option value="deleteProperty">Delete - Existing Property</option>
          <option value="update">Update - Existing App</option>
        </select>
        <button @click="returnToTables" class="secondary-button top-return-button">Return to Tables</button>
      </div>

      <div v-if="message.text" :class="['message', message.type]">
        {{ message.text }}
      </div>

      <div class="button-container" v-if="updateType === 'existing'">
        <button @click="addSelectedApps" class="primary-button">Add Selected Apps</button>
      </div>

      <div class="button-container" v-if="updateType === 'delete'">
        <button @click="deleteSelectedApps" class="delete-button">Delete Selected Apps</button>
      </div>

      <div v-if="updateType === 'update'" class="instructions">
        <p>Please Select Application to Update</p>
      </div>

      <div v-if="(updateType === 'existing' || updateType === 'delete' || updateType === 'update') && selectedTable" class="table-wrapper">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th v-for="header in tableHeaders" :key="header" @click="sortTable(header)">
                  {{ header }} <span v-if="sortColumn === header">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in sortedTableContents" :key="row.PartitionKey + '-' + row.RowKey">
                <td>
                  <!-- Use checkbox for Add - Existing App -->
                  <input
                    v-if="updateType === 'existing' || updateType === 'delete'"
                    type="checkbox"
                    :value="row"
                    v-model="selectedApplications"
                  />
                  <!-- Use radio button for Update - Existing App -->
                  <input
                    v-if="updateType === 'update'"
                    type="radio"
                    :value="row"
                    v-model="selectedApp"
                    @change="onAppSelected"
                  />
                </td>
                <td v-for="header in tableHeaders" :key="header" class="table-cell">{{ row[header] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="updateType === 'new' && selectedTable" class="form-wrapper">
        <form @submit.prevent="addNewApp" class="new-app-form">
          <div v-for="field in newAppFields" :key="field" class="form-group">
            <label :for="field">{{ field }}</label>
            <div v-if="isSelectField(field)">
              <select
                v-model="newApp[field]"
                :id="field"
                :name="field"
                :class="{ 'form-control': true, 'is-invalid': !isConditionalDisabled(field) && invalidFields.includes(field) }"
              >
                <option v-for="option in getOptionsForField(field)" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>
            <div v-else>
              <input
                v-model="newApp[field]"
                :id="field"
                :name="field"
                :disabled="isFieldDisabled(field) || isConditionalDisabled(field)"
                :class="{ 'form-control': true, 'is-invalid': !isConditionalDisabled(field) && invalidFields.includes(field) }"
              />
            </div>
          </div>
          <button type="submit" class="primary-button">Add New App</button>
        </form>
        <div v-if="formError" class="error-message">
          Please fill in all the required fields.
        </div>
        <div v-if="message.text" :class="['message', message.type]" class="bottom-message">
          {{ message.text }}
        </div>
      </div>

      <div v-if="updateType === 'newProperty' && selectedTable" class="form-wrapper">
        <form @submit.prevent="addNewProperty" class="new-property-form">
          <div class="form-group">
            <label for="propertyName">Property Name</label>
            <input
              v-model="newPropertyName"
              id="propertyName"
              name="propertyName"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="propertyValue">Property Value</label>
            <input
              v-model="newPropertyValue"
              id="propertyValue"
              name="propertyValue"
              class="form-control"
              required
            />
          </div>
          <button type="submit" class="primary-button">Add New Property</button>
        </form>
      </div>

      <div v-if="updateType === 'deleteProperty' && selectedTable" class="form-wrapper">
        <form @submit.prevent="deleteProperty" class="delete-property-form">
          <div class="form-group">
            <label>Select Property to Delete</label>
            <div v-for="header in deletableHeaders" :key="header" class="radio-container">
              <input type="radio" :value="header" v-model="propertyNameToDelete" />
              <span class="property-name">{{ header }}</span>
            </div>
          </div>
          <button type="submit" class="primary-button">Delete Property</button>
        </form>
      </div>

      <div v-if="updateType === 'update' && selectedApp" class="form-wrapper update-app-wrapper">
        <form @submit.prevent="updateSelectedApp" class="update-app-form">
          <div v-for="(value, field) in selectedApp" :key="field" class="form-group">
            <label :for="field" class="update-label">{{ field }}</label>
            <input
              v-model="selectedApp[field]"
              :id="field"
              :name="field"
              class="form-control update-control"
              :disabled="isFieldDisabled(field)"
            />
          </div>
          <button type="submit" class="primary-button">Update App</button>
        </form>
      </div>

      <div class="actions" v-if="updateType">
        <button @click="returnToTables" class="secondary-button">Return to Tables</button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/views/components/NavBar.vue';
import { msalInstance } from '@/auth.js';
import { listAzureTables, getTableContents, updateClientTable, deleteClientTable, updateEntity } from '@/azure-devops-api.js';
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      tables: [],
      selectedTable: null,
      updateType: null,
      tableContents: [],
      tableHeaders: [],
      deletableHeaders: [],
      sortColumn: '',
      sortOrder: 'asc',
      selectedApplications: [],
      selectedApp: null, // Set to null initially
      newApp: {
        PartitionKey: 'appinfo-pk1',
        RowKey: '',
        AppName: '',
        Architecture: 'x64',
        Channel: '',
        CheckSum: '',
        CheckSum64: '',
        Description: '',
        Install: '',
        Publisher: '',
        SoftwareName: '',
        Stream: '',
        InstallFileType: 'exe',
        UninstallFileType: 'exe',
        URI: '',
        DownloadFileType: 'exe',
        Uninstall: '',
        UninstallerLocation: '',
        Version: '',
        ExitCode: '@(0, 3010, 1605, 1614, 1641)',
        AssignmentScopeAvailable: '',
        AssignmentScopeRequired: '',
        ProjectURL: '',
        TableVersion: '',
        IconPath: '',
        InstallExperience: 'system',
      },
      newAppFields: [
        'PartitionKey', 'RowKey', 'AppName', 'Architecture', 'Channel', 'CheckSum', 'CheckSum64', 'Description', 'Install', 'Publisher',
        'SoftwareName', 'Stream', 'InstallFileType', 'UninstallFileType', 'URI', 'DownloadFileType', 'Uninstall', 'UninstallerLocation',
        'Version', 'ExitCode', 'AssignmentScopeAvailable', 'AssignmentScopeRequired', 'ProjectURL', 'TableVersion', 'IconPath', 'InstallExperience'
      ],
      message: {
        text: '',
        type: '', // 'success' or 'error'
      },
      formError: false,
      invalidFields: [],
      newPropertyName: '',
      newPropertyValue: '',
      propertyNameToDelete: '',
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    sortedTableContents() {
      if (!this.sortColumn) return this.tableContents;
      return this.tableContents.slice().sort((a, b) => {
        const aValue = a[this.sortColumn];
        const bValue = b[this.sortColumn];
        if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    },
  },
  watch: {
    invalidFields: {
      handler(newInvalidFields) {
        console.log("Invalid fields updated:", newInvalidFields);
      },
      deep: true
    },
    'newApp.UninstallFileType'(newValue) {
      if (newValue === 'msi') {
        this.newApp.Uninstall = 'autouninstall';
        this.newApp.UninstallerLocation = ''; // Set UninstallerLocation to an empty string
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!msalInstance.getAllAccounts().length) {
      next('/login');
    } else {
      next();
    }
  },
  async created() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      const accessTokenRequest = {
        account: accounts[0],
        scopes: ['https://storage.azure.com/.default'],
      };
      try {
        await msalInstance.acquireTokenSilent(accessTokenRequest);
        this.tables = await listAzureTables();
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    }
  },
  methods: {
    async onTableChange() {
      this.updateType = null;
      this.selectedApplications = [];
      this.tableContents = [];
      this.tableHeaders = [];
    },
    async onUpdateTypeChange() {
this.selectedApplications = []; // Reset selected applications when update type changes
this.selectedApp = null; // Reset selected app

try {
  let tableToFetch = this.selectedTable;

  if (this.updateType === 'existing') {
    tableToFetch = 'GENWorkstationApps'; // Ensure apps are fetched from GENWorkstationApps
  } 

  if (this.updateType === 'existing' || this.updateType === 'delete' || this.updateType === 'update') {
    const contents = await getTableContents(tableToFetch);
    this.tableContents = contents;
    const headers = Object.keys(contents[0]);
    this.tableHeaders = ['AppName', 'Version', ...headers.filter(h => h !== 'AppName' && h !== 'Version')];
  } else if (this.updateType === 'new') {
    this.newApp.RowKey = await this.getNextRowKey();
  } else if (this.updateType === 'deleteProperty') {
    await this.loadTableHeaders();
  }
} catch (error) {
  console.error("Error fetching table contents:", error);
}
},
    async loadTableHeaders() {
      try {
        const contents = await getTableContents(this.selectedTable);
        const headers = Object.keys(contents[0]);
        this.deletableHeaders = headers.filter(h => !['PartitionKey', 'RowKey', 'timestamp', 'etag'].includes(h));
      } catch (error) {
        console.error('Error loading table headers:', error);
      }
    },
    async getNextRowKey() {
      try {
        const contents = await getTableContents(this.selectedTable);
        console.log('Fetched table contents:', contents);

        const rowKeys = contents.map(item => {
          const parsedKey = parseInt(item.rowKey, 10);
          if (isNaN(parsedKey)) {
            console.error('Invalid RowKey encountered, non-numeric:', item.rowKey);
            return 0;
          }
          return parsedKey;
        });

        const maxRowKey = Math.max(...rowKeys);
        const nextRowKey = (maxRowKey + 1).toString().padStart(4, '0');
        console.log('Calculated next RowKey:', nextRowKey);
        return nextRowKey;
      } catch (error) {
        console.error("Error fetching RowKey:", error);
        return '0001';
      }
    },
    async addSelectedApps() {
      if (!this.selectedApplications.length) {
        alert('Please select at least one application.');
        return;
      }
      try {
        for (let app of this.selectedApplications) {
          const nextRowKey = await this.getNextRowKeyForSelectedTable();
          const updatedApp = { ...app, rowKey: nextRowKey };

          const response = await updateClientTable(this.selectedTable, updatedApp);
          if (response.status !== 200) {
            this.message.text = `Error updating table: ${response.message}`;
            this.message.type = 'error';
            return;
          }
        }
        this.message.text = `Table '${this.selectedTable}' updated successfully with selected applications.`;
        this.message.type = 'success';
      } catch (error) {
        console.error('Error updating table:', error);
        this.message.text = `Error updating table: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async deleteSelectedApps() {
      if (!this.selectedApplications.length) {
        alert('Please select at least one application.');
        return;
      }
      try {
        const deletePromises = this.selectedApplications.map(async (app) => {
          console.log('Deleting entity:', { partitionKey: app.partitionKey, rowKey: app.rowKey });
          if (!app.partitionKey || !app.rowKey) {
            console.error('Invalid entity keys:', app);
            return;
          }
          return deleteClientTable(this.selectedTable, app.partitionKey, app.rowKey);
        });

        const results = await Promise.all(deletePromises);
        const errors = results.filter(result => result.status !== 200);

        if (errors.length > 0) {
          this.message.text = `Error deleting some entities from table: ${errors.map(err => err.message).join(', ')}`;
          this.message.type = 'error';
        } else {
          this.message.text = `Selected applications deleted from table '${this.selectedTable}' successfully.`;
          this.message.type = 'success';
        }
        
        this.onUpdateTypeChange(); // Refresh the table contents
        this.selectedApplications = []; // Reset selected applications
      } catch (error) {
        console.error('Error deleting from table:', error);
        this.message.text = `Error deleting from table: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async addNewApp() {
      console.log("Form submission started");
      this.formError = false;
      this.invalidFields = [];
      
      if (!this.validateForm()) {
        this.formError = true;
        console.log("Form error set to true, invalid fields:", this.invalidFields);
        return;
      }

      try {
        const response = await updateClientTable(this.selectedTable, this.newApp);
        if (response.status === 200) {
          this.message.text = `New app added to table '${this.selectedTable}' successfully.`;
          this.message.type = 'success';
          await this.resetForm();
        } else {
          this.message.text = `Error adding new app: ${response.message}`;
          this.message.type = 'error';
        }
      } catch (error) {
        console.error('Error adding new app:', error);
        this.message.text = `Error adding new app: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async addNewProperty() {
    if (!this.newPropertyName || !this.newPropertyValue) {
      alert('Please fill in both property name and value.');
      return;
    }

    try {
      const contents = await getTableContents(this.selectedTable);
      const updatePromises = contents.map(entity => {
        entity[this.newPropertyName] = this.newPropertyValue;
        return updateEntity(this.selectedTable, entity);
      });

      await Promise.all(updatePromises);
      this.message.text = `New property '${this.newPropertyName}' added to all entities in table '${this.selectedTable}' successfully.`;
      this.message.type = 'success';
      this.newPropertyName = '';
      this.newPropertyValue = '';
    } catch (error) {
      console.error('Error adding new property:', error);
      this.message.text = `Error adding new property: ${JSON.stringify(error)}`;
      this.message.type = 'error';
    }
  },
    async deleteProperty() {
      if (!this.propertyNameToDelete) {
        alert('Please select the property name.');
        return;
      }

      try {
        const contents = await getTableContents(this.selectedTable);
        const updatePromises = contents.map(entity => {
          delete entity[this.propertyNameToDelete];
          return updateEntity(this.selectedTable, entity);
        });

        await Promise.all(updatePromises);
        this.message.text = `Property '${this.propertyNameToDelete}' deleted from all entities in table '${this.selectedTable}' successfully.`;
        this.message.type = 'success';
        this.propertyNameToDelete = '';
        await this.loadTableHeaders(); // Refresh the list of properties
      } catch (error) {
        console.error('Error deleting property:', error);
        this.message.text = `Error deleting property: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async updateSelectedApp() {
      if (!this.selectedApp) {
        this.message.text = `Please select an app to update.`;
        this.message.type = 'error';
        return;
      }

      try {
        const response = await updateEntity(this.selectedTable, this.selectedApp);
        if (response.status === 200) {
          this.message.text = `App '${this.selectedApp.AppName}' updated successfully in table '${this.selectedTable}'.`;
          this.message.type = 'success';
          this.selectedApp = null; // Reset selected app after update
        } else {
          this.message.text = `Error updating app: ${response.message}`;
          this.message.type = 'error';
        }
      } catch (error) {
        console.error('Error updating app:', error);
        this.message.text = `Error updating app: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    validateForm() {
      const requiredFields = [
        'AppName', 'Architecture', 'Description', 'Install', 'Publisher', 'SoftwareName',
        'InstallFileType', 'UninstallFileType', 'URI', 'DownloadFileType', 'Uninstall', 'UninstallerLocation',
        'Version', 'ExitCode', 'AssignmentScopeAvailable', 'AssignmentScopeRequired', 'ProjectURL', 'IconPath', 'InstallExperience'
      ];

      this.invalidFields = requiredFields.filter(field => {
        if (this.newApp.UninstallFileType === 'exe' && (field === 'Uninstall' || field === 'UninstallerLocation')) {
          return !this.newApp[field];
        }
        if (this.newApp.UninstallFileType === 'msi' && (field === 'Uninstall' || field === 'UninstallerLocation')) {
          return false; // Do not mark these fields as invalid for 'msi'
        }
        return !this.newApp[field];
      });

      console.log("Validation complete, invalid fields:", this.invalidFields);
      return this.invalidFields.length === 0;
    },
    async resetForm() {
      this.newApp = {
        PartitionKey: 'appinfo-pk1',
        RowKey: await this.getNextRowKey(),
        AppName: '',
        Architecture: 'x64',
        Channel: '',
        CheckSum: '',
        CheckSum64: '',
        Description: '',
        Install: '',
        Publisher: '',
        SoftwareName: '',
        Stream: '',
        InstallFileType: 'exe',
        UninstallFileType: 'exe',
        URI: '',
        DownloadFileType: 'exe',
        Uninstall: '',
        UninstallerLocation: '',
        Version: '',
        ExitCode: '',
        AssignmentScopeAvailable: '',
        AssignmentScopeRequired: '',
        ProjectURL: '',
        TableVersion: '',
        IconPath: '',
        InstallExperience: 'system',
      };
    },
    async getNextRowKeyForSelectedTable() {
      try {
        const contents = await getTableContents(this.selectedTable);
        console.log('Fetched table contents for selected table:', contents);

        const rowKeys = contents.map(item => {
          if (item.rowKey && typeof item.rowKey === 'string') {
            const parsedKey = parseInt(item.rowKey, 10);
            if (isNaN(parsedKey)) {
              console.error('Invalid rowKey encountered, non-numeric:', item.rowKey);
              return 0;
            }
            return parsedKey;
          } else {
            console.error('rowKey missing or not a string:', item);
            return 0;
          }
        });
        const maxRowKey = Math.max(...rowKeys);
        const nextRowKey = (maxRowKey + 1).toString().padStart(4, '0');
        console.log('Calculated next rowKey for selected table:', nextRowKey);
        return nextRowKey;
      } catch (error) {
        console.error("Error fetching rowKey for selected table:", error);
        return '0001';
      }
    },
    onAppSelected() {
      // This method is called when an app is selected
      this.message.text = ''; // Clear any existing messages
    },
    isFieldDisabled(field) {
      return field === 'PartitionKey' || field === 'RowKey';
    },
    isConditionalDisabled(field) {
      if (this.newApp.UninstallFileType === 'msi' && (field === 'Uninstall' || field === 'UninstallerLocation')) {
        return true;
      }
      return false;
    },
    isSelectField(field) {
      return ['Architecture', 'DownloadFileType', 'InstallExperience','InstallFileType', 'UninstallFileType'].includes(field);
    },
    getOptionsForField(field) {
      const options = {
        Architecture: ['x64', 'x86'],
        DownloadFileType: ['exe', 'msi', 'zip'],
        InstallExperience: ['system', 'user'],
        UninstallFileType: ['exe', 'msi'],
        InstallFileType: ['exe', 'msi'],
      };
      return options[field] || [];
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
    returnToTables() {
      this.$router.push('/storage-tables-list');
    },
  },
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.update-table-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.select-element {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.button-container {
  margin-bottom: 20px; /* Add this line to increase space below the button */
}

.primary-button,
.secondary-button,
.delete-button {
  font-family: "Roboto", sans-serif;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button,
.delete-button {
  background-color: #38b35f;
  color: white;
  text-decoration: none; /* Remove underline */
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.delete-button:hover {
  background-color: #d41d1d;
}

.secondary-button {
  background-color: #555;
  color: white;
}

.secondary-button:hover {
  background-color: #444;
}

.button-link {
  display: inline-block; /* Ensure it's block-level */
  text-align: center;
}

.top-return-button {
  align-self: center;
}

.table-wrapper {
  width: 100%;
  overflow: hidden;
}

.table-scroll-container {
  position: relative;
  width: 100%;
}

.table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
}

.table-scroll-content {
  height: 1px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  min-width: 600px;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

th {
  background-color: #f4f4f4;
  cursor: pointer;
}

th span {
  font-size: 0.8rem;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.table-cell {
  max-width: 150px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.form-wrapper {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.new-app-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.new-app-form .form-group {
  margin-bottom: 1rem;
}

.new-app-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.new-app-form .form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.new-app-form .form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.new-app-form .primary-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #38b35f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.new-app-form .primary-button:hover {
  background-color: #1c7ed6;
}

.is-invalid {
  border-color: #dc3545 !important;
  background-color: #fff !important;
}

.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
}

.new-app-form .form-control[disabled] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.new-property-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.new-property-form .form-group {
  margin-bottom: 1rem;
}

.new-property-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.new-property-form .form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.new-property-form .form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.new-property-form .primary-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #38b35f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.new-property-form .primary-button:hover {
  background-color: #1c7ed6;
}

.delete-property-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.delete-property-form .form-group {
  margin-bottom: 1rem;
}

.delete-property-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.delete-property-form .radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: "Roboto", sans-serif;
}

.delete-property-form .radio-container input[type="radio"] {
  margin-right: 0.5rem;
}

.delete-property-form .primary-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #38b35f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.delete-property-form .primary-button:hover {
  background-color: #1c7ed6;
}

.instructions {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.update-app-wrapper {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.update-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.update-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 15px;
}

.update-control[disabled] {
  background-color: #e9ecef;
  cursor: not-allowed;
}
</style>
