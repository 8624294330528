import { PublicClientApplication } from '@azure/msal-browser';
import store from "@/store";


const msalConfig = {
    auth: {
        clientId: '1da955b1-4ce4-481a-a857-0423ac697927',
        authority: 'https://login.microsoftonline.com/bcc93690-805b-4911-b8c1-6e447795ef8b',
        //redirectUri: 'http://localhost:8080',
        redirectUri: 'https://www.cyaniccloud.com',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

// Update isLoggedIn state based on whether there are any accounts
store.commit('setLoggedIn', msalInstance.getAllAccounts().length > 0);


const getAccount = () => {

    return msalInstance.getAllAccounts()[0];
};

const promptForConsent = async () => { 
    try {
        const authRequest = {
            scopes: ['https://management.azure.com/user_impersonation']
        };
        await msalInstance.loginPopup(authRequest);
    } catch (error) {
        console.error('Failed to prompt for consent:', error);
        throw error;
    }
};

const logout = () => {
    const account = getAccount();
    if (account) {
        msalInstance.logoutRedirect({
            account,
            //postLogoutRedirectUri: "http://localhost:8080", // update to the URL you want
            postLogoutRedirectUri: "https://www.cyaniccloud.com", // update to the URL you want

        });
        store.commit('setLoggedIn', false);
    }
};


export { msalInstance, getAccount, promptForConsent, logout };
