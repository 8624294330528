<template>
  <div class="tenant-baseline-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>

    <div class="baseline-container">
      <!-- Tab Navigation -->
      <div class="tab-header">
        <button :class="{'active-tab': activeTab === 'cac'}" @click="activeTab = 'cac'">
          Azure / M365 Baseline (CaC)
        </button>
        <button :class="{'active-tab': activeTab === 'network'}" @click="activeTab = 'network'">
          Azure Network Baseline (IaC)
        </button>
      </div>

      <!-- Windowed Content Area -->
      <div class="tab-content-window">
        <!-- Azure / M365 Baseline (CaC) -->
        <div v-if="activeTab === 'cac'" class="tab-content">
          <!-- Buttons: On-Board Tenant and Apply Baseline -->
          <div class="button-container">
            <button @click="showForm('onboard')" class="action-button">On-Board Tenant</button>
            <button @click="showPipelineDropdown" class="action-button">Apply Baseline</button>
          </div>

          <!-- On-Board Tenant Form -->
          <div v-if="showOnboardForm" class="main-content">
            <form @submit.prevent="handleSubmit" class="form">
              <div class="form-group">
                <label for="client-code">Client Code:</label>
                <input 
                  type="text" 
                  id="client-code" 
                  v-model="clientCode" 
                  @input="validateClientCode"
                  :class="{ 'is-invalid': invalidFields.includes('clientCode') || clientCodeError }"
                  required 
                />
                <span v-if="clientCodeError" class="error-message">{{ clientCodeError }}</span>
                <span v-if="clientCodeSuccess" class="success-message">{{ clientCodeSuccess }}</span>
              </div>
              <div class="form-group">
                <label for="domain">Domain:</label>
                <input 
                  type="text" 
                  id="domain" 
                  v-model="domain" 
                  :class="{ 'is-invalid': invalidFields.includes('domain') }"
                  required 
                />
              </div>
              <div class="form-group">
                <label for="username">Username:</label>
                <input 
                  type="text" 
                  id="username" 
                  v-model="username" 
                  :class="{ 'is-invalid': invalidFields.includes('username') }"
                  required 
                />
              </div>
              <div class="form-group">
                <label for="password">Password:</label>
                <input 
                  type="password" 
                  id="password" 
                  v-model="password" 
                  :class="{ 'is-invalid': invalidFields.includes('password') }"
                  required 
                />
              </div>
              <button type="submit" class="primary-button" :disabled="clientCodeError || !clientCodeValidated">Submit</button>
            </form>
            <div v-if="formError" class="error-message">
              Please fill in all the required fields.
            </div>
            <div v-if="message.text" :class="['message', message.type]">
              {{ message.text }}
            </div>
          </div>

          <!-- Apply Baseline: Pipeline Dropdown and Run Button -->
          <div v-if="showPipelineList" class="pipeline-container">
            <label for="pipeline-select">Select Pipeline:</label>
            <select v-model="selectedPipelineId" id="pipeline-select">
              <option v-for="pipeline in pipelines" :key="pipeline.id" :value="pipeline.id">
                {{ pipeline.name }} ({{ formatFolder(pipeline.folder) }})
              </option>
            </select>
            <button @click="runSelectedPipeline" class="primary-button" :disabled="!selectedPipelineId">Run Pipeline</button>

            <!-- Show success/error message after pipeline action -->
            <div v-if="pipelineMessage.text" :class="['message', pipelineMessage.type]">
              {{ pipelineMessage.text }}
            </div>
          </div>
        </div>

        <!-- Azure Network Baseline (IaC) -->
        <div v-if="activeTab === 'network'" class="tab-content">
          <h2>Azure Network Baseline (IaC)</h2>
          <p>Content for Azure Network Baseline (IaC) goes here.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/views/components/NavBar.vue';
import { msalInstance } from '@/auth.js';
import { fetchPipelinesWithFilterandProject, runPipeline, triggerAppDeployBuild } from '@/azure-devops-api.js';
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      activeTab: 'cac',
      showOnboardForm: false,
      showPipelineList: false,
      pipelines: [],
      selectedPipelineId: null,
      pipelineMessage: { text: '', type: '' },
      clientCode: '',
      domain: '',
      username: '',
      password: '',
      formError: false,
      invalidFields: [],
      message: { text: '', type: '' },
      clientCodeError: '',
      clientCodeSuccess: '',
      clientCodeValidated: false,
    };
  },
  computed: mapState(['isLoggedIn']),
  methods: {
    showForm(formType) {
      if (formType === 'onboard') {
        this.showOnboardForm = true;
        this.showPipelineList = false;
      }
    },
    showPipelineDropdown() {
      this.fetchPipelines();
      this.showPipelineList = true;
      this.showOnboardForm = false;
    },
    async fetchPipelines() {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
          };
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          const projectName = 'Aegis';
          this.pipelines = await fetchPipelinesWithFilterandProject(response.accessToken, projectName);
        }
      } catch (error) {
        console.error('Error fetching pipelines:', error);
      }
    },
    async runSelectedPipeline() {
      if (!this.selectedPipelineId) return;
      try {
        const accounts = msalInstance.getAllAccounts();
        if (!accounts || !accounts.length) {
          throw new Error('User is not logged in');
        }

        const accessTokenRequest = {
          account: accounts[0],
          scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
        };
        const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
        const accessToken = response.accessToken;

        const selectedPipeline = this.pipelines.find(p => p.id === this.selectedPipelineId);
        const projectId = selectedPipeline._links.self.href.split('/')[4];

        await runPipeline(accessToken, projectId, this.selectedPipelineId);

        this.pipelineMessage = {
          text: 'Pipeline started successfully!',
          type: 'success',
        };
      } catch (error) {
        console.error('Failed to run pipeline:', error);
        this.pipelineMessage = {
          text: 'Failed to start the pipeline. Please try again.',
          type: 'error',
        };
      }
    },
    formatFolder(folder) {
      return folder.replace('\\', '').trim();
    },
    validateForm() {
      this.invalidFields = [];
      if (!this.clientCode) this.invalidFields.push('clientCode');
      if (!this.domain) this.invalidFields.push('domain');
      if (!this.username) this.invalidFields.push('username');
      if (!this.password) this.invalidFields.push('password');
      return this.invalidFields.length === 0;
    },
    validateClientCode() {
      const clientCodePattern = /^[A-Z]{3}$/;
      if (!clientCodePattern.test(this.clientCode)) {
        this.clientCodeError = 'Client code must be exactly 3 uppercase letters.';
        this.clientCodeSuccess = '';
        this.clientCodeValidated = false;
      } else {
        this.clientCodeError = '';
        this.clientCodeSuccess = `Client Code is valid!`;
        this.clientCodeValidated = true;
      }
    },
    async handleSubmit() {
      if (!this.validateForm()) {
        this.formError = true;
        return;
      }

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
          };
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          const accessToken = response.accessToken;

          const formData = {
            clientCode: this.clientCode,
            domain: this.domain,
            username: this.username,
            password: this.password,
          };

          console.log('Form submitted:', formData);

          const projectId = '1b238677-4a8d-4243-827e-88a0558c085a';
          const pipelineBuildId = '75';

          const buildResponse = await triggerAppDeployBuild(
            accessToken,
            projectId,
            pipelineBuildId,
            this.clientCode,
            this.domain,
            this.username,
            this.password
          );

          if (buildResponse && buildResponse.state === 'inProgress') {
            this.message.text = 'Pipeline triggered successfully!';
            this.message.type = 'success';

            // Clear the fields
            this.clientCode = '';
            this.domain = '';
            this.username = '';
            this.password = '';
            this.clientCodeValidated = false;
            this.formError = false;
            this.invalidFields = [];
          } else {
            this.message.text = 'Failed to trigger the pipeline.';
            this.message.type = 'error';
          }
        } else {
          throw new Error('No accounts available for authentication.');
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        this.message.text = 'Error triggering build: ' + error.message;
        this.message.type = 'error';
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tenant-baseline-page {
  font-family: 'Roboto', sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.baseline-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.tab-header {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
}

.tab-header button {
  background-color: #f9f9f9;
  color: #333;
  padding: 10px 20px;
  border: none;
  font-size: 1rem;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid transparent;
}

.tab-header button.active-tab {
  border-bottom: 2px solid #1e90ff;
  background-color: #fff;
  font-weight: bold;
}

.tab-header button:hover {
  background-color: #e0e0e0;
}

.tab-content-window {
  width: 100%;
  max-width: 600px;
  margin: 40px 0 40px 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.pipeline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem; /* Adds space between items */
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 20px; /* Ensures it has a top margin */
}

#pipeline-select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem; /* Space between dropdown and button */
}

.primary-button {
  font-family: 'Roboto', sans-serif;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #38b35f;
  color: white;
  transition: background-color 0.3s ease;
  display: block;
  margin-top: 1rem;
  width: auto; /* Auto width for better alignment */
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.primary-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

form {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #1c7ed6;
  outline: none;
}

.is-invalid {
  border-color: #dc3545 !important;
  background-color: #fff !important;
}

.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
  border-radius: 4px;
  margin-top: 1rem;
  text-align: center;
}

.success-message {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 10px;
  border-radius: 4px;
  margin-top: 1rem;
  text-align: center;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.action-button {
  background-color: #f9f9f9;
  color: #333;
  padding: 10px 20px;
  border: none;
  font-size: 1rem;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #e0e0e0;
}

.action-button.active {
  border-bottom: 2px solid #1e90ff;
  background-color: #fff;
  font-weight: bold;
}

.main-content {
  font-family: 'Roboto', sans-serif;
  background-color: #fff; /* Background color to differentiate the content */
  padding: 20px; /* Padding around the content */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
  margin-top: 20px; /* Space between form and any other content */
  max-width: 600px; /* Limit the width of the form */
  width: 100%; /* Ensure it takes up full width on smaller screens */
}


</style>
