<template>
  <div id="app">
    <header></header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: mapState(['isLoggedIn']),
};
</script>


